import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class DronesInfoImages extends Component {
  render() {
    let { subtitle, heading, content, background_color, text_color, column } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="drones-info-images" style={{backgroundColor: background_color}}>
          <div className="drones-info-images__inner container">
            <div className="drones-info-images__left">
              <h5>{subtitle}</h5>
              <h2>{heading}</h2>
              <div className="drones-info-images__paragraph">
                <p style={{color: text_color}}>{content}</p>
              </div>
            </div>
            <div className="drones-info-images__right">
              { column && column.map((el, i) => {
                return (
                  <div className="drones-info-images__item" key={i}>
                    <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                    <h4 style={{color: text_color}}>{el.title}</h4>
                    <div className="drones-info-images__content" style={{color: text_color}} dangerouslySetInnerHTML={{ __html: el.content }} />
                    <div className="line" style={{backgroundColor: text_color}} />
                  </div>
                )
              }) }
            </div>
          </div>
        </section>
      </Fade>
    )
  }
}

// DronesInfoImages.defaultProps = {
//   data: {subtitle, heading, content, column},
// }


export default DronesInfoImages