import React, { Component } from 'react'

import Fade from 'react-reveal/Fade'
import Map from './map'
import { Marker, Phone } from '../components/icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {

  state = {
    name: '',
    phone: '',
    email: '',
    enquiry: '',
    emailValidation: '',
    nameValidation: ''
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if ( this.state.name === '' || this.state.email === '' ) {
      if ( this.state.name === '' ) {
        this.setState({result: 'fail', nameValidation: 'Please enter your name.' })
      } else {
        this.setState({result: 'fail', nameValidation: '' })
      }
      if ( this.state.email === '' ) {
        this.setState({result: 'fail', emailValidation: 'Please enter an email address.' })
      } else {
        this.setState({result: 'fail', emailValidation: '' })
      }
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => this.setState({result: 'success', msg: 'Thanks for contacting us. One of the team will be in touch shortly.', emailValdiation:'', nameValdiation:''}))
        .catch(error => this.setState({result: 'fail', msg: error}));
    }
  }

  render() {
    return (
      <div className="ciwv__image">
        <div className="ciwv__case-study">
          { this.state.result !== 'success' &&
            <Fade bottom distance="40px">
              <form
                className="form form--contact"
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="form__row">
                  <label htmlFor="name">Name</label>
                  <input type="text" name="name" id="name" onChange={this.handleChange} required />
                  { this.state.nameValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.nameValidation }} />
                  }
                </div>
                <div className="form__row">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" id="email" onChange={this.handleChange} />
                  { this.state.emailValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.emailValidation }} />
                  }
                </div>
                <div className="form__row">
                  <label htmlFor="phone">Phone Number</label>
                  <input type="text" name="phone" id="phone" onChange={this.handleChange} />
                </div>
                <div className="form__row">
                  <label htmlFor="enquiry">Enquiry</label>
                  <textarea name="enquiry" id="enquiry" onChange={this.handleChange} rows={3} />
                </div>
                <div className="form__row form__row--submit">
                  <button className="btn btn--primary" type="submit">Send Enquiry</button>
                </div>
              </form>
            </Fade>
          }
          { this.state.result === 'success' &&
            <>
              <div className="form__success" dangerouslySetInnerHTML={{__html: 'Thanks for getting in contact with FSC Range.  A member of our team will be in touch soon.'}} />
            </>
          }
        </div>
      </div>
    )
  }
}

class Contact extends Component {

  render() {
    let { title, address, phone, google_map } = this.props
    return (
      <section className="ciwv ciwv--columns ciwv--contact">
        <div className="ciwv__inner container">
          <div className="ciwv__left">
            <h2 className="ciwv__subtitle">{title}</h2>
            <div className="ciwv__content">
              <p><Marker color="#2a2728" /> <span>{address}</span></p>
            </div>
            <div className="ciwv__content">
              <p><Phone color="#2a2728" /> <a href={'tel:'+phone}>{phone}</a></p>
            </div>
            <div className="ciwv__map">
              <Map {...google_map} />
            </div>
          </div>
          <ContactForm />
        </div>
      </section>
    )
  }
}

export default Contact
