import React, { Component } from 'react'
import { LinkedIn } from '../../components/icons'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'


const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal modal--team active" : "modal modal--team";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class TeamCard extends Component {

  state = { 
    show: false, 
  }

  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  render() {
    return (
      <div className="team__item">
        <Fade bottom cascade distance="40px">
          { this.props.acf.profile_picture && <img src={this.props.acf.profile_picture.localFile.childImageSharp.fixed.src} alt={`FSC Group - ${this.props.title}`} onClick={() => this.showModal()} /> }
          <div className="team__content">
            <h4 onClick={() => this.showModal()}>{this.props.title} <a href={this.props.acf.linkedin} rel="noopener noreferrer" target="_blank"><LinkedIn color="#4B4C4E" /></a></h4>
            <span>{this.props.acf.position}</span>
            <span className="team__link" onClick={() => this.showModal()}>View Profile</span>
          </div>
        </Fade>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="team__inner container">
            <div className="team__content-wrap">
              <div className="team__image">
                { this.props.acf.profile_picture && <img src={this.props.acf.profile_picture.localFile.childImageSharp.fixed.src} alt={`FSC Group - ${this.props.title}`} /> }
                <div className="slim-contact__button">
                  <Link to="/contact">Contact FSC Group</Link>
                </div>
              </div>
              <div className="team__content">
                <h4>{this.props.title} <a href={this.props.acf.linkedin} target="_blank" rel="noopener noreferrer"><LinkedIn color="#4B4C4E" /></a></h4>
                <span>{this.props.acf.position}</span>
                <div className="team__bio" dangerouslySetInnerHTML={{__html: this.props.acf.full_bio }} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default TeamCard