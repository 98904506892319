import React, { Component } from 'react'

import { Play } from '../components/icons'
import Fade from 'react-reveal/Fade'


const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class ContentVideoImage extends Component {

  state = { 
    show: false, 
  }

  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  render() {

    return (
      <section className="cvi">
        <Fade left cascade distance="40px">
          <div className="cvi__inner container">
            <h2 className="cvi__title">{this.props.title}</h2>
            <div className="cvi__content" dangerouslySetInnerHTML={{ __html: this.props.content }} />
          </div>
        </Fade>
        <div className="cvi__image-wrap">
          <div className="cvi__image">
            <Fade bottom distance="0">
              { this.props.background_image && <img src={this.props.background_image.localFile.childImageSharp.fixed.src} alt={this.props.title} /> }
            </Fade>
          </div>
          <div className={this.props.video ? 'cvi__video-wrap' : 'cvi__video-wrap no-video'}>
            <div className="container">
              <Fade cascade bottom distance="40px">
                { this.props.video &&
                  <div className="cvi__video-thumb">
                    { this.props.video_image && <img src={this.props.video_image.localFile.childImageSharp.fixed.src} alt={this.props.title} onClick={this.showModal} /> }
                    <span className="cvi__video-text" onClick={this.showModal}>
                      <Play color="#FFF" />
                      Watch the FSC Story
                    </span>
                  </div>
                }
              </Fade>
            </div>
            { this.props.video_content &&
              <Fade cascade right distance="40px">
                <div className={this.props.video ? 'cvi__video-content' : 'cvi__video-content no-video'} dangerouslySetInnerHTML={{ __html: this.props.video_content }} />
              </Fade>
            }
          </div>
        </div>
        { this.props.video_content &&
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <div className="video-wrapper" dangerouslySetInnerHTML={{__html: this.state.show && this.props.video }} />
          </Modal>
        }
      </section>
    )
  }
}

export default ContentVideoImage