import React, { Component } from 'react'
import Image from '../assets/images/phone.svg'
import Fade from 'react-reveal/Fade'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone_number: '',
    message: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if ( this.state.type === '' ) {
      this.setState({ type_valid: false })
      return false
    }

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let { form_title } = this.props
    let props = {
      ref: 'form',
      name: 'landing_contact',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    return (
      <form {...props}>
        <div className='form__row'>
          <h2>{form_title}</h2>
        </div>
        <div className='form__row'>
          <label>Name</label>
          <input type='text' name='name' onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <label>Email</label>
          <input type='email' name='email' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <label>Contact Number</label>
          <input type='text' name='phone_number' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <label>Enquiry</label>
          <textarea name='enquiry' onChange={this.handleChange} />
        </div>
        <div className='form__row form__row--submit'>
          <button class='btn' type='submit'>Submit</button>
        </div>
        { this.state.result === 'success' &&
          <p className='form__success'>Thanks for contacting us. One of the team will be in touch shortly.</p>
        }
      </form>
    )
  }
}

class LandingContact extends Component {
  render() {
    let { title, person, form_title } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="landing-contact">
          <div className="landing-contact__inner container">
            <div className="landing-contact__left">
            <h2>{title}</h2>
            { person && person.map((el, i) => {
                return (
                  <div className="landing-contact__item" key={i}>
                    <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                    <div className="landing-contact__item--right">
                      <h4>{el.name}</h4>
                      <p className="landing-contact__content"> {el.role} </p>
                      <ul>
                        <img src={Image} alt="FSC Group"></img>
                        <li>{el.phone}</li>
                      </ul> 
                    </div>
                  </div>
                )
              }) }
            </div>
            <div className="landing-contact__right">
              <Form form_title={form_title} />
            </div>
          </div>
        </section>
      </Fade>
    )
  }
}



export default LandingContact