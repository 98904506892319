import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class HeadingContentLanding extends Component {
  render() {
    let data = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="heading-content-landing">
          <div className="heading-content-landing__inner container">
            <h1>{data.title}</h1>
            <div className="heading-content-landing__bottom">
              <div className ="heading-content-landing__borderbox"> </div>
              <div className="heading-content-landing__content" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
          </div>
        </section>
      </Fade>
    )
  }
}

HeadingContentLanding.defaultProps = {
  data: {},
}

export default HeadingContentLanding