import React, { Component } from 'react'

import DownloadForm from './download-form'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class QuickContact extends Component {

  state = {
    show: false,
    name: '',
    email: '',
  }

  showForm = () => {
    this.setState({ show: true });
  };

  hideForm = () => {
    this.setState({ show: false });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail'}));
  }

  render() {
    let data = this.props
    let download = data.download ? data.download.source_url : '#'
    const showHideClassName = this.state.show ? "download-form active" : "download-form"
    const showHideOverlayClassName = this.state.show ? "download-form__overlay active" : "download-form__overlay"
    return (
      <section className="quick-contact">
        <div className="quick-content__wrapper">
          <div className="quick-contact__row">
            <div className="quick-contact__image">
              { data.left_image && <img src={data.left_image.localFile.childImageSharp.fixed.src} alt={data.right_title} /> }
            </div>
            <div className="quick-contact__content-wrap">
              <h2>{data.right_title}</h2>
              <div className="quick-contact__content" dangerouslySetInnerHTML={{ __html: data.right_content }} />
            </div>
          </div>
          <div className="quick-contact__row">
            <div className="quick-contact__image">
              { data.right_image && <img src={data.right_image.localFile.childImageSharp.fixed.src} alt={data.left_title} /> }
              <div className="quick-contact__download" onClick={() => this.showForm()}>
                <span>{data.download_title ? data.download_title : `Find out more` }</span>
              </div>
            </div>
            <div className="quick-contact__content-wrap">
              <h2>{data.left_title}</h2>
              <div className="quick-contact__content" dangerouslySetInnerHTML={{ __html: data.left_content }} />
              <div className="quick-contact__form-wrap">
                <div className="quick-contact__content">
                  <h4>{data.form_title}</h4>
                  { this.state.result !== 'success' &&
                    <form 
                      className="form"
                      name="quick-contact"
                      method="post"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                      <input type="hidden" name="form-name" value="quick-contact" />
                      <div className="form__row">
                        <div className="form__col">
                          <label>Name</label>
                          <input type="text" name="name" onChange={this.handleChange} required />
                        </div>
                        <div className="form__col">
                          <label>Email or Phone</label>
                          <input type="text" name="email" onChange={this.handleChange} required />
                        </div>
                        <div className="form__col form__col--button">
                          <button className="btn btn--primary">Contact</button>
                        </div>
                      </div>
                    </form>
                  }
                  { this.state.result === 'success' &&
                    <>
                      <div className="form__success" dangerouslySetInnerHTML={{__html: 'Thanks for getting in contact with FSC-Group.  A member of our team will be in touch soon.'}} />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={showHideClassName}>
          <button className="modal__close" onClick={() => this.hideForm()} />
          <DownloadForm download={download} download_title={data.download_title} />
        </div>
        <div className={showHideOverlayClassName} onClick={() => this.hideForm()} />
      </section>
    )
  }
}

QuickContact.defaultProps = {
  data: {},
}

export default QuickContact