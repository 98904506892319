import React, { Component } from 'react'

import letterF from '../assets/images/letter-f.png'
import letterS from '../assets/images/letter-s.png'
import letterC from '../assets/images/letter-c.png'

import Energy from '../assets/images/energy.jpg'
import Road from '../assets/images/road.jpg'
import Tunnel from '../assets/images/tunnel.jpg'

class Letters extends Component {

  state = {
    position: 0
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (document.documentElement.clientWidth > 1024) {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      this.setState({ position: currentScroll })
    }
  }
  render() {
    return (
      <section className="letters">
        <div className="letters__wrap">
          <div className="letters__f">
            <img className="letters__letter" src={letterF} alt="FSC Group Service - F" />
            <img style={{transform: `translateY(${this.state.position * 0.15}px)`}} className="letters__bg" src={Energy} alt="FSC Group Service - Urban Renewal" />
          </div>
          <div className="letters__s">
            <img className="letters__letter" src={letterS} alt="FSC Group Service - S" />
            <img style={{transform: `translateY(${this.state.position * 0.17}px)`}} className="letters__bg" src={Road} alt="FSC Group Service - Urban Renewal" />
          </div>
          <div className="letters__c">
            <img className="letters__letter" src={letterC} alt="FSC Group Service - C" />
            <img style={{transform: `translateY(${this.state.position * 0.19}px)`}} className="letters__bg" src={Tunnel} alt="FSC Group Service - Urban Renewal" />
          </div>
        </div>
      </section>
    )
  }
}

Letters.defaultProps = {
  
}

export default Letters