import React, { Component } from 'react'

import TeamCard from './cards/team'

class Team extends Component {

  render() {
    return (
      <section className="team">
        { this.props.team &&
          <div className="team__inner container">
            { this.props.team.map((el, i) => <TeamCard {...el} key={i} />) }
          </div>
        }
      </section>
    )
  }
}

export default Team