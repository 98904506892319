import React, { Component } from 'react'

class FullWidthImage extends Component {
  render() {
  	const { image } = this.props
    return (
      <section className="full-width-image">
        <img src={image.localFile.childImageSharp.original.src} alt='FSC Group' />
      </section>
    )
  }
}

export default FullWidthImage