import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import TeamCard from '../components/cards/team'

class AllTeam extends Component {
  render() {
    let { data } = this.props
    const team = data.allWordpressWpTeam
    return (
      <>
        <section className="team">
          { team &&
            <div className="team__inner container">
              { team.edges.map((el, i) => <TeamCard {...el.node} key={i} />) }
            </div>
          }
        </section>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpTeam {
          edges {
            node {
              title
              slug
              acf {
                short_bio
                full_bio
                linkedin
                profile_picture {
                  localFile {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <AllTeam data={data} {...props} />}
  />
)