import React, { Component } from 'react'

class BuildingItBetter extends Component {
  render() {
    let { banner_image, image_caption } = this.props
    return (
      <section className="banner-image banner-image--text">
        { banner_image && <img src={banner_image.localFile.childImageSharp.fixed.src} alt="FSC Group - Building It Better" /> }
        <div className="banner-image__inner container">
          <h3>{image_caption}</h3>
        </div>
      </section>
    )
  }
}

export default BuildingItBetter