import React, { Component } from 'react'
import { Link } from 'gatsby'

class SectorCard extends Component {
  render() {
    let { title, slug, acf } = this.props

    return (
      <div className="sectors__item">
        { acf.featured_image && <img src={acf.featured_image.localFile.childImageSharp.original.src} alt={title} /> }
        <span>{title}</span>
        <Link to={`/sector/${slug}`} />
      </div>
    )
  } 
}

export default SectorCard
