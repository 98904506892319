import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SectorCard from '../components/cards/sector'

class Sectors extends Component {

  render() {
    let { data } = this.props
    const posts = data.allWordpressWpSector.edges
    return (
      <section className="sectors">
        <div className="sectors__inner container">
          { posts && posts.filter(el => el.node.slug !== 'test-sector').map((el, i) => (<SectorCard key={i} {...el.node} />)) }
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpSector {
          edges {
            node {
              title
              slug
              acf {
                featured_image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Sectors data={data} {...props} />}
  />
)