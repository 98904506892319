import React, { Component } from 'react'

class BannerImage extends Component {
  render() {
    return (
      <section className="banner-image">
        { this.props.image && <img src={this.props.image.localFile.childImageSharp.original.src} alt="FSC Group" /> }
      </section>
    )
  }
}

export default BannerImage