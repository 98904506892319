import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ThreeColumnContent extends Component {
  render() {
    let data = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="columns" style={{backgroundColor: data.background_color}}>
          <div className="columns__inner container">
            { data.column && data.column.map((el, i) => {
              return (
                <div className="columns__item" key={i}>
                  <h4 style={{color: data.text_color}}>{el.title}</h4>
                  <div className="columns__content" style={{color: data.text_color}} dangerouslySetInnerHTML={{ __html: el.content }} />
                  <div className="line" style={{backgroundColor: data.text_color}} />
                </div>
              )
            }) }
          </div>
        </section>
      </Fade>
    )
  }
}

ThreeColumnContent.defaultProps = {
  data: {},
}

export default ThreeColumnContent