import React, { Component } from 'react'

import encode from '../utils/encode'

class DownloadForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      company: '',
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClose = () => {
    document.querySelector('.download-form').classList.remove('active')
    document.querySelector('.download-form__overlay').classList.remove('active')
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success', msg: 'Thanks for your interest in downloading our guide. Click here to download the document.', emailValdiation:'', nameValdiation:''}))
      .catch(error => this.setState({result: 'fail', msg: error}));
  }

  render() {
    let {download_title} = this.props 
    return (
      <>
        { this.state.result !== 'success' &&
          <form 
            className="form form--downloadform" 
            name="downloadform" 
            method="post" 
            data-netlify="true" 
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="downloadform" />
            <div className="form__title"> 
              <h2 className="downloadform__heading">{download_title}</h2>
            </div>
            <div className="form__row">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" onChange={this.handleChange} required />
              { this.state.nameValidation !== '' &&
                <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.nameValidation }} />
              }
            </div>
            <div className="form__row">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" onChange={this.handleChange} required />
              { this.state.emailValidation !== '' &&
                <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.emailValidation }} />
              }
            </div>
            <div className="form__row">
              <label htmlFor="phone">Contact Number</label>
              <input type="text" name="phone" id="phone" onChange={this.handleChange} required />
            </div>
            <div className="form__row">
              <label htmlFor="enquiry">Company</label>
              <input type="text" name="company" id="company" onChange={this.handleChange} />
            </div>
            <div className="form__row form__row--submit">
              <button className="btn btn--primary" type="submit">Submit</button>
            </div>
          </form>
        }
        { this.state.result === 'success' &&
          <div className="download-form__success"> 
            <div dangerouslySetInnerHTML={{__html: 'Thanks for getting in contact with FSC-Group.  A member of our team will be in touch soon.'}} />
          </div>
        }
      </>
    )
  }
}

DownloadForm.defaultProps = {
  download_title: `Get in touch`,
}


export default DownloadForm