import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'

class Services extends Component {

  state = {
    accordionOpen: false,
  }

  _toggleAccordion = (accordionItem) => {
    this.setState({accordionOpen: {[accordionItem]: !this.state.accordionOpen[accordionItem]} })
  }

  render() {
    let { data } = this.props
    const services = data.allWordpressWpService.edges
    return (
      <div className="services__accordion">
        <Fade bottom cascade distance="40px">
          <div className="accordion">
            { services && services.map((el, i) => {
              return (
                <div className={this.state.accordionOpen[i] ? 'accordion__item active' : 'accordion__item'} key={i}>
                  <h4 className="accordion__trigger" onClick={() => this._toggleAccordion(i)}>
                    {RenderIcon(el.node.acf.icon)}
                    <span className="accordion__title" dangerouslySetInnerHTML={{ __html: el.node.title }} />
                    <span className="accordion__expand">
                      {this.state.accordionOpen[i] ? 'Close' : 'Expand'}
                      <span>{this.state.accordionOpen[i] ? '-' : '+'}</span>
                    </span>
                  </h4>
                  <div className="accordion__content">
                    <h5>{el.node.acf.subtitle}</h5>
                    <div className="accordion__excerpt" dangerouslySetInnerHTML={{ __html: el.node.acf.excerpt }} />
                    { el.node.acf.learn_more_link && <Link className="btn btn--underline" to={el.node.acf.learn_more_link}>Learn More</Link> }
                  </div>
                </div>
              )
            }) }
          </div>
        </Fade>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpService {
          edges {
            node {
              title
              slug
              acf {
                subtitle
                icon
                excerpt
                learn_more_link
                featured_image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Services data={data} {...props} />}
  />
)