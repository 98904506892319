import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ImageContent extends Component {
  render() {
    return (
      <section className="quick-contact quick-contact--grey">
        { this.props.rows && this.props.rows.map((el, i) => {
          return (
            <div className="quick-contact__row" key={i}>
              <div className="quick-contact__image">
                <img src={el.image.localFile.childImageSharp.fixed.src} alt={el.title} />
              </div>
              <div className="quick-contact__content-wrap">
                <Fade bottom distance="40px">
                  <h2>{el.title}</h2>
                </Fade>
                <Fade bottom distance="40px">
                  <div className="quick-contact__content" dangerouslySetInnerHTML={{ __html: el.content }} />
                </Fade>
              </div>
            </div>
          )
        }) }
      </section>
    )
  }
}

export default ImageContent