import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Services from './services'

class OurServices extends Component {

  render() {
    let { title, subtitle, content } = this.props
    return (
      <section className="services-block">
        <div className="services-block__inner container">
          <div className="services-block__description">
            <Fade left distance="40px">
              <>
                <h2 className="services-block__title">{title}</h2>
                <h3 className="services-block__subtitle">{subtitle}</h3>
                <div className="services-block__content" dangerouslySetInnerHTML={{ __html: content }} />
              </>
            </Fade>
          </div>
          <Services />
        </div>
      </section>

    )
  }
}

OurServices.defaultProps = {
  title: `We work across and combine several industry sectors`,
  content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis.</p>`,
}

export default OurServices