import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import { ArrowRight } from '../components/icons'

import CaseStudyCard from '../components/cards/case-study'

class CaseStudies extends Component {

  render() {
    let { data } = this.props
    const posts = data.allWordpressWpCaseStudy.edges
    return (
      <section className="case-studies">
        <div className="case-studies__inner">
          <div className="container">
            <h2 className="case-studies__title">Here how we are helping companies like yours.</h2>
            <Link className="case-studies__link" to="/case-studies">More Case Studies <ArrowRight color="#2a2728" /></Link>
          </div>
          <div className="case-studies__items">
            { posts && posts.map((el, i) => <CaseStudyCard key={i} {...el.node} />) }
          </div>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpCaseStudy(filter: {slug: {ne: "test-case-study"}}) {
          edges {
            node {
              title
              slug
              acf {
                subtitle
                featured_image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <CaseStudies data={data} {...props} />}
  />
)
