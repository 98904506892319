import React, { Component } from 'react'
//import Fade from 'react-reveal/Fade'

import { Play } from '../components/icons'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class LandingContentVideo extends Component {

  state = { 
    show: false, 
  }

  showModal = () => {
    this.setState({ show: true })
    let src = document.querySelector('.video-wrapper iframe').getAttribute('src')
    src += '&autoplay=1'
    document.querySelector('.video-wrapper iframe').setAttribute('src', src)
  }

  hideModal = () => {
    this.setState({ show: false })
    let src = document.querySelector('.video-wrapper iframe').getAttribute('src')
    let stopVideoSrc = src.replace('&autoplay=1', '')
    document.querySelector('.video-wrapper iframe').setAttribute('src', stopVideoSrc)
  }

  render() {
    let {title, content, image, video} = this.props

    return (
      <section className="landing-content-video">
        <div className="landing-content-video__inner container">
          <div className="landing-content-video__left">
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: content }}/>
          </div>
          <div className="landing-content-video__right" onClick={()=> this.showModal()}>
            <img src={image.localFile.childImageSharp.original.src} alt='FSC Group' />
            <Play color="#FFFFFF" />
          </div>
        </div>
        { this.props.video &&
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <div className="video-wrapper" dangerouslySetInnerHTML={{__html: video }} />
          </Modal>
        }
      </section>
    )
  }
}

export default LandingContentVideo