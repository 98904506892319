import React from "react"
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

const CrossPartnerBlock = ({logo, title, subtitle, featured_image, button_text, link}) => {

    return (
        <section className="cross-partner-block__container">
            <div className="cross-partner-block">
                <BackgroundImage className="cross-partner-block__image" fluid={featured_image.localFile.childImageSharp.fluid} />
                <div className="cross-partner-block__info-section">
                    <Img fixed={logo.localFile.childImageSharp.fixed} className="cross-partner-block__logo" alt="logo" />
                    <h2 className="cross-partner-block__title">{title}</h2>
                    <div className="cross-partner-block__footer">
                        <p className="cross-partner-block__subtitle">{subtitle}</p>
                        <a href={link} className="cross-partner-block__button">{button_text}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CrossPartnerBlock