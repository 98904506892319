import React, { Component } from 'react'

class TitleContent extends Component {
  render() {
    let data = this.props
    return (
      <section className="title-content">
        <div className="title-content__inner container">
          <h1>{data.title}</h1>
          <div className="title-content__content" dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </section>
    )
  }
}

TitleContent.defaultProps = {
  data: {},
}

export default TitleContent