import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class TwoColumnImageContent extends Component {
  render() {
    const { image, title, content } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="two-column-image-content--columns">
          <div className="two-column-image-content__inner">
            <div className="two-column-image-content__left">
              <img src={image.localFile.childImageSharp.original.src} alt='FSC Group' />
            </div>
            <div className="two-column-image-content__right">
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </section>
      </Fade>
    )
  }
}

export default TwoColumnImageContent