import React, { Component } from 'react'
import he from 'he'
import { Link } from 'gatsby'

class CaseStudyCard extends Component {
  render() {
    let { title, slug, acf } = this.props

    return (
      <div className="case-studies__item card card--case-study">
        <figure>
          { acf.featured_image && <img src={acf.featured_image.localFile.childImageSharp.original.src} alt={title} /> }
        </figure>
        <span>{acf.subtitle}</span>
        <h4>{he.decode(title)}</h4>
        <Link to={`/case-study/${slug}`} />
      </div>
    )
  } 
}

export default CaseStudyCard
