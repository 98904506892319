import React, { Component } from 'react'


class EmbedContent extends Component {

  render() {
    let { background_color, html } = this.props

    return (
      <section className='embed-content' style={{backgroundColor: background_color}}>
        <div className='embed-content__inner' dangerouslySetInnerHTML={{__html: html}} />
      </section>
    )
  }
}

export default EmbedContent
