import React, { Component } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

class Map extends Component {

  render() {
    const location = { lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) };

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={13}
        defaultCenter={location}
        options={{ 
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          clickableIcons: true,
          scrollwheel:false,
        }}
      >
        <Marker
          position={location}
        />
      </GoogleMap>
    ));

    return (
      <div className='map'>
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDWFK_I3Eic-gDP8ekIu0MDV2ze_Zj5RHM&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%`, margin: `0` }} />}
          mapElement={<div style={{ height: `100%` }} className={'map__map'} />}
        />
      </div>
    );
  }
}

export default Map
