import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class PackageTwoColumn extends Component {
  render() {
    const { subtitle, title, content, packages } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="package-two-column--columns">
          <div className="package-two-column__inner container">
            <div className="package-two-column__left">
              <p className="subheading">{subtitle}</p>
              <h4 className="title">{title}</h4>
              <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            { packages && packages.map((el, i) => {
              return (
                <div className="package-two-column__middle" key={i}>
                  { el.recommended &&
                    <p className="recommended">Recommended</p>
                  }
                  <p className="package_title">{el.title}</p>
                  <h4 className="price">{el.price}</h4>
                  <div className="info" dangerouslySetInnerHTML={{ __html: el.content }} />
                  <p className="features_title">Top Features</p>
                  { el.features &&
                    <ul className="features">
                      { el.features.map((x, i) => ( <li className="feature">{x.text}</li> )) }
                    </ul>
                  }
                  <button>{el.button.text}</button>
                </div>
              )
            }) }
          </div>
        </section>
      </Fade>
    )
  }
}

export default PackageTwoColumn