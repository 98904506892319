import React, { Component } from 'react'
import Instafeed from 'react-instafeed'

const instafeedTarget = 'instafeed'

class Instagram extends Component {

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <section id={instafeedTarget} className="instagram">
        <Instafeed
          limit='8'
          ref='instafeed'
          resolution='standard_resolution'
          sortBy='most-recent'
          target={instafeedTarget}
          template='<div class="instagram__item"><a href="{{link}}" target="_blank" rel="noopener noreferrer"><img src={{image}} alt="FSC Group"></a></div>'
          userId='8346903148'
          clientId='2d6c2b89b5184af69e2922e8847e5c23'
          accessToken='8346903148.1677ed0.b88b01c66d6c42df9ea2323b3df9a17b'
        />
      </section>
    )
  }
}

export default Instagram
