import React, { Component } from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import { Instagram, Facebook, YouTube, LinkedIn, Twitter, Pinterest } from '../components/icons'

class SlimContact extends Component {

  render() {
    let { data } = this.props
    const settings = data.allWordpressAcfOptions.edges[0].node.options
    return (
      <section className="slim-contact">
        <div className="slim-contact__inner container">
          <div className="slim-contact__button">
            <Fade bottom distance="40px">
              <Link to={this.props.button_link}>{this.props.button_text}</Link>
            </Fade>
          </div>
          <Fade bottom cascade distance="40px">
            <ul className="slim-contact__social">
              { settings.instagram && <li className="instagram-icon"><a href={settings.instagram} target="_blank" rel="noopener noreferrer"><Instagram color="#2a2728" /></a></li> }
              { settings.facebook && <li><a href={settings.facebook} target="_blank" rel="noopener noreferrer"><Facebook color="#2a2728" /></a></li> }
              { settings.youtube && <li><a href={settings.youtube} target="_blank" rel="noopener noreferrer"><YouTube color="#2a2728" /></a></li> }
              { settings.linkedin && <li className="linkedin-icon"><a href={settings.linkedin} target="_blank" rel="noopener noreferrer"><LinkedIn color="#2a2728" /></a></li> }
              { settings.twitter && <li><a href={settings.twitter} target="_blank" rel="noopener noreferrer"><Twitter color="#2a2728" /></a></li> }
              { settings.pinterest && <li><a href={settings.pinterest} target="_blank" rel="noopener noreferrer"><Pinterest color="#2a2728" /></a></li> }
            </ul>
          </Fade>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                instagram
                linkedin
                facebook
                youtube
                twitter
                pinterest
              }
            }
          }
        }
      }
    `}
    render={data => <SlimContact data={data} {...props} />}
  />
)
