import React, { Component } from 'react'

class BasicPage extends Component {
  render() {
    return (
      <>
        <div className="basic">
          <div className="basic__inner container">
            <h1>{this.props.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
          </div>
        </div>
      </>
    )
  }
}

export default BasicPage