import React, { Component } from 'react'
import { Link } from 'gatsby'


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone_number: '',
    message: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if ( this.state.type === '' ) {
      this.setState({ type_valid: false })
      return false
    }

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let { form_title } = this.props
    let props = {
      ref: 'form',
      name: 'alt_landing_form_banner',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    return (
      <form {...props}>
        <div className='form__row'>
          <h2>{form_title}</h2>
        </div>
        <div className='form__row'>
          <label>Name</label>
          <input type='text' name='name' placeholder='Name' onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <label>Email</label>
          <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <label>Contact Number</label>
          <input type='text' name='phone_number' placeholder='Phone Number' onChange={this.handleChange} required  />
        </div>
        <div className='form__row'>
          <label>Enquiry</label>
          <textarea name='enquiry' placeholder='Message' onChange={this.handleChange} />
        </div>
        <div className='form__row form__row--submit'>
          <button class='btn' type='submit'>Submit</button>
        </div>
        { this.state.result === 'success' &&
          <p className='form__success'>Thanks for contacting us. One of the team will be in touch shortly.</p>
        }
      </form>
    )
  }
}

class AltLandingFormBanner extends Component {

  render() {
    let { title, subheading, form_title, image, logo } = this.props
    return (
      <>

        <section className='alternate-landing-form-banner'>
          <img src={image.localFile.childImageSharp.original.src} alt={title} />
          <div className='container'>
            <Link className='alternate-landing-form-banner__logo' to='/'>
              <img src={logo.localFile.childImageSharp.original.src} alt='FSC Group' />
            </Link>
            <div className='alternate-landing-form-banner__inner'>
              <div className="content">
                <h2>{title}</h2>
                {/* <div> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> </div> */}
                <div dangerouslySetInnerHTML={{ __html: subheading }} />
              </div>
              <Form form_title={form_title} />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default AltLandingFormBanner