import React, { Component } from 'react'

import Contact from '../components/contact'
import BuildingItBetter from '../components/building-it-better'

class ContactPage extends Component {
  render() {
    return (
      <>
        <Contact {...this.props} />
        <BuildingItBetter {...this.props} />
      </>
    )
  }
}

export default ContactPage