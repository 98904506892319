import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ThreeColumnImage extends Component {
  render() {
    let { text_color, background_color, column } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="three-column-image" style={{backgroundColor: background_color}}>
          <div className="three-column-image__inner container">
            { column && column.map((el, i) => {
              return (
                <div className="three-column-image__item" key={i}>
                  <img src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                  <h4 style={{color: text_color}}>{el.title}</h4>
                  <div className="three-column-image__content" style={{color: text_color}} dangerouslySetInnerHTML={{ __html: el.content }} />
                  <div className="line" style={{backgroundColor: text_color}} />
                </div>
              )
            }) }
          </div>
        </section>
      </Fade>
    )
  }
}

export default ThreeColumnImage