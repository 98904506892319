import React, { Component } from 'react'

import Fade from 'react-reveal/Fade'

import { Play } from '../components/icons'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class VideoImage extends Component {

  state = { 
    show: false, 
  }

  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  render() {

    return (
      <section className="cvi cvi--basic">
        <div className="cvi__image-wrap">
          <div className="cvi__image">
            { this.props.background_image && <img src={this.props.background_image.localFile.childImageSharp.fixed.src} alt={this.props.title} /> }
          </div>
          <div className={this.props.video ? 'cvi__video-wrap' : 'cvi__video-wrap no-video'}>
            <div className="container">
              { this.props.video &&
                <Fade cascade bottom distance="40px">
                  <div className="cvi__video-thumb">
                    { this.props.video_image && <img src={this.props.video_image.localFile.childImageSharp.fixed.src} alt={this.props.title} onClick={this.showModal} /> }
                    <span className="cvi__video-text" onClick={this.showModal}>
                      <Play color="#FFF" />
                      Watch the FSC Story
                    </span>
                  </div>
                </Fade>
              }
            </div>
            { this.props.video_content &&
              <Fade cascade right distance="40px">
                <div className={this.props.video ? 'cvi__video-content' : 'cvi__video-content no-video'} dangerouslySetInnerHTML={{ __html: this.props.video_content }} />
              </Fade>
            }
          </div>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="video-wrapper" dangerouslySetInnerHTML={{__html: this.state.show && this.props.video }} />
        </Modal>
      </section>
    )
  }
}

export default VideoImage