import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Testimonial extends Component {
  render() {
    let testimonial = this.props.testimonial[0]
    return (
      <section className="testimonial">
        <Fade bottom cascade distance="40px">
          { testimonial &&
            <div className="testimonial__inner container"> 
              <div className="testimonial__quote" dangerouslySetInnerHTML={{ __html: testimonial.quote }} />
              <div className="testimonial__meta">
                { testimonial.acf.logo && <img src={testimonial.acf.logo.localFile.childImageSharp.fixed.src} alt={testimonial.title} /> }
                <span>{testimonial.acf.name}</span> 
              </div>
            </div>
          }
        </Fade>
      </section>
    )
  }
}

export default Testimonial