import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class TwoColumnRepeater extends Component {
  render() {
    const { items } = this.props
    return (
      <Fade bottom cascade distance="40px">
        <section className="two-column-repeater--columns">
          <div className="two-column-repeater__inner container">
            { items && items.map((el, i) => {
              return (
                <div className="two-column-repeater__item" key={i}>
                  <div className="two-column-repeater__left">
                    <h2 className="two-column-repeater__title">{el.title}</h2>
                    <div className="two-column-repeater__content" dangerouslySetInnerHTML={{ __html: el.content }} />
                  </div>
                  <div className="two-column-repeater__right">
                    <img className="two-column-repeater__image" src={el.image.localFile.childImageSharp.original.src} alt={el.title} />
                  </div>
                </div>
              )
            }) }
          </div>
        </section>
      </Fade>
    )
  }
}

export default TwoColumnRepeater